// laraval base  url
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Stripe public key
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
export const PAYPAL_SECRET = process.env.REACT_APP_PAYPAL_SECRET;
export const getApiUrl = (endpoint) => API_BASE_URL + endpoint;

export const INSTALL_APP = getApiUrl("install-app");
export const LOGIN_API = getApiUrl("login");
export const LOGIN_TOKEN_API = getApiUrl("login-with-token");
export const SIGNUP_API = getApiUrl("signup");
export const CUSTOMER_FORGET = getApiUrl("forget");
export const CUSTOMER_RESET = getApiUrl("reset-password");

export const LOGOUT_API = getApiUrl("logout");

export const CAPTURE_ORDER_PAYMENT = getApiUrl("capture_order_payment");
export const CAPTURE_ORDER_PAYMENT_USING_SAVED_CARDS = getApiUrl(
  "capture_order_payment_using_saved_cards"
);
export const CAPTURE_ORDER_PAYMENT_USING_CREDITS = getApiUrl(
  "capture_order_payment_using_credits"
);

export const GET_CUSTOMER_INFO_DOMAIN = getApiUrl("get_customer_info_domain");
export const REGISTER_USER_BY_SHOP = getApiUrl("register-user-by-shop");

export const GET_CUSTOMER_ACCOUNT_DETAILS = getApiUrl(
  "get_customer_account_detail"
);
export const GET_CUSTOMER_ADDRESS_DETAILS = getApiUrl("get_address_detail");
export const UPDATE_CUSTOMER_ACCOUNT_DETAILS = getApiUrl(
  "save_customer_account_detail"
);
export const UPDATE_CUSTOMER_SIGNUP = getApiUrl("update-customer-signup");
export const UPDATE_CUSTOMER_SIGNUP_ADDRESS = getApiUrl(
  "update-customer-signup-address"
);

export const GET_ALL_ORDERS_API = getApiUrl("orders");
export const GET_FULL_ORDERS_API = getApiUrl("orders/get_all_orders");

export const GET_MESSAGE = getApiUrl("message");
export const DELETE_ORDERS_API = getApiUrl("order/delete");
export const UPDATE_ORDER_ITEM_API = getApiUrl("order/update");
export const UPDATE_ADDRESS_ORDER_ITEM_API = getApiUrl("order/update_address");
export const COUPON_GET_API = getApiUrl("discount");
export const REMOVE_LINE_ITEM_API = getApiUrl("order/remove_lineitem");
export const SEARCH_ORDER_API = getApiUrl("order/search");
export const SORT_ORDER_API = getApiUrl("order/sort");
export const COMBINE_ORDERS_API = getApiUrl("order/combine");
export const SAVE_CARD = getApiUrl("save_card");
export const GET_CARDS = getApiUrl("get_card");
export const GET_PAYPAL_CARDS = getApiUrl("get_paypal_card");
export const SAVE_PAYPAL_CARD = getApiUrl("save_paypal_card");
export const PAYMENT_API = getApiUrl("checkout");
export const PAYMENT_INTENT = getApiUrl("payment_intent");
export const PAYMENT_INTENT_CARD = getApiUrl("payment_intent_card");
export const GET_AUTOPAY = getApiUrl("autopay");
export const GET_PAYPAL_AUTOPAY = getApiUrl("paypalAutopay");
export const SET_AUTOPAY = getApiUrl("set_autopay");
export const SET_PAYAPL_AUTOPAY = getApiUrl("set_paypal_autopay");
export const PRIMARY_CARDS = getApiUrl("set_primary_card");
export const PRIMARY_PAYPAL_CARDS = getApiUrl("set_paypal_primary_card");
export const TRANSACTION_API = getApiUrl("transactions");
export const ANALYTICS_API = getApiUrl("analytic_info");
export const ANALYTICS_GRAPH_API = getApiUrl("analytic_graph_info");
export const REMOVE_CARD_API = getApiUrl("remove_card");
export const REMOVE_PAYPAL_CARD_API = getApiUrl("remove_paypal_card");
export const ADD_SHIPPING_ADDRESS = getApiUrl("order/add_shipping_address");
export const GET_REMAINING_AMOUNT_API = getApiUrl("remainingAmt");

export const UPLOAD_LICENCE = getApiUrl("upload-licence-file");
export const SUBSCRIBE_CONNECTION = getApiUrl("connection/subscribe");
export const GET_CONNECTION = getApiUrl("connection/merchant-connections");
export const GET_CONNECTION_DETAIL = getApiUrl(
  "connection/get-subscription-setting"
);
export const REMOVE_CONNECTION_SUBSCRIPTION = getApiUrl(
  "connection/cancel-subscription"
);
export const SUBSCRIPTION_PREFERENCES = getApiUrl(
  "connection/subscription-preferences"
);
export const SUBSCRIPTION_SETTINGS = getApiUrl(
  "connection/subscription-setting"
);
export const GET_ALL_CONNECTION = getApiUrl(
  "connection/unsubscribed-connections"
);
export const UPDATE_SYNC_SETTING = getApiUrl("connection/merchant-setting");
export const GET_SYNC_SETTING = getApiUrl("connection/get-merchant-setting");
export const GET_ALL_PLANS = getApiUrl("subscription-plans");
export const CREATE_PLAN = getApiUrl("billing/create-subscription");
export const APPROVE_PLAN = getApiUrl("billing/approve-subscription");
export const CHECK_SUBSCRIPTION = getApiUrl("billing/check-subscription");
export const CANCEL_SUBSCRIPTION = getApiUrl("billing/cancel-subscription");
export const COLLECTION_PRODUCTS = getApiUrl("connection/products");
export const TRACKING_STATUS = getApiUrl("order/update-tracking-status");
export const GET_OLD_MERCHANT_CONNECTIONS = getApiUrl(
  "merchant/plan-unsubscribed-connections"
);
export const SUBSCRIBE_DEFAULT_CONNECTION = getApiUrl(
  "default-connection/subscribe"
);
export const UPDATE_INVENTORY_LOCATION = getApiUrl("update-inventory-location");
export const FETCH_ALL_INVENTORY_LOCATIONS = getApiUrl(
  "merchant-shop-locations"
);

// Branding
export const BRAND_LOGO = process.env.REACT_APP_BRAND_LOGO;
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME;
export const BRAND_LOGIN_BANNER = process.env.REACT_APP_BRAND_LOGIN_BANNER;
export const BRAND_HELP_URL = process.env.REACT_APP_BRAND_HELP_URL;
