import { Box, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmFreePlan = ({ selectPlan, openDialog, setOpenDialog }) => {
  let navigate = useNavigate();

  return (
    <>
      <section className="bt_batch_overview py-0">
        <div className="container-fluid px-0">
          <div className="row g-0">
            <div className="col-12 d-flex">
              <div className="auth_bg">
                <div className="container-fluid bg-white box-shadow">
                  <div className="row g-0 x">
                    <div className=" text-center">
                      <Grid container spacing={5} mt={0}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{display: "flex", justifyContent: "end", alignItems:"center"}}>
                        <div className="tex-start upcoming-heading confirm_free_plan_title">
                            <Typography variant='h5' sx={{}}>
                              Are You Sure?
                            </Typography>
                            <Typography variant='body1' sx={{fontWeight: 700, mt:2}}>
                              Don't Miss Out!
                            </Typography>
                            <Typography variant='body1'  sx={{fontWeight: 700}}>
                              Consider the Benefits
                            </Typography>
                            <Typography variant='body1'  sx={{fontWeight: 700}}>
                              Of Platinum
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{display: "flex", justifyContent: "start", alignItems:"center"}}>
                        <Box className='confirm_free_plan_img_div' sx={{ml: { xs:"-1rem !important", sm:"0.5rem !important" }}}>
                              <img src="assets/images/premium.png" alt="header-img"/>
                            </Box>
                          <div className="old-plan-container" style={{display: "flex", justifyContent: "center",alignItems: "center",}}>
                            <div className="">
                              <img src="assets/images/confirm-free-plan.svg" alt="header-img" width="200px" />
                            </div>
                        </div>
                        </Grid>
                      </Grid>
                      <Box sx={{ mt: 3 }}>
                        <Typography variant='body1' sx={{ color: "#555555" }}>
                          Platinum Members get All Freemium benefits +
                        </Typography>
                      </Box>
                      <Box sx={{display:"flex", justifyContent:"center", alignItems: "center"}}>
                      <Box sx={{
                        backgroundColor: "#d4c1aa",
                        color: "#fff",
                        mt:3,
                        padding: "2.2rem",
                        border: "1px",
                        borderRadius:"2rem",
                        // width: "50%",
                        // ml:"25%"
                      }}>
                        <Typography variant='h5' sx={{ color: "#fff", mt:"-10px" }}>
                        PLATINUM
                        </Typography>
                        <Box sx={{ 
                            background: "#fff",
                            p: "2.1rem",
                            pt: "1rem",
                            borderRadius:"2rem",
                            mt:2
                          }}>
                          <Typography variant='h6' sx={{ color: "#7f7f7f", fontWeight: 600 }}>
                          Membership
                          </Typography>
                          <Box>
                            <img src="assets/images/member-star.png" alt="header-img" />
                          </Box>
                          <List dense sx={{display: "flex", alignItems:"center", justifyContent: "center", flexDirection:"column"}}>
                            {platinumMemberBenefitsList.map(b => (
                              <ListItem sx={{width:"fit-content"}} >
                                <ListItemText
                                  primary={b}
                                  sx={{color: "#7f7f7f", fontSize: "14px"}}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Box>
                      </Box>
                      <Box>
                      <Grid container spacing={5}>
                        <Grid item xs={0} sm={4} md={4} lg={4} xl={4}>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{mt: "1rem", mb: "1rem"}}>
                          <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <div
                              className="upcoming-confirm p-2"
                              onClick={(e) => selectPlan(openDialog.data)}
                              style={{ background: '#f2f2f2', color: '#7f7f7f' }}>
                              NOT NOW
                            </div>
                            <div
                              className="upcoming-confirm p-2"
                              onClick={(e) => {
                                // navigate(openDialog.path)
                                setOpenDialog({
                                  open: false,
                                  data: null,
                                  path: ""
                                })
                              }}>
                              UPGRADE!
                            </div>
                            </Box>
                        </Grid>
                        <Grid item xs={0} sm={4} md={4} lg={4} xl={4}>

                        </Grid>
                      </Grid>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConfirmFreePlan;

const platinumMemberBenefitsList = [
  "Product discounts, typically 5-10%",
  "Early access to samples",
  "Account manager",
  "Personalized onboarding",
  "Priority support",
  "1-2 business day fulfillment",
  "3% Milestone Rewards",
  "Custom data connection available",
  "Low monthly commitment"
]
